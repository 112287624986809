import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Divider from '../components/Divider';
import ModularBlocks from '../components/ModularBlocks';

const DemoWebinarPageTemplate = ({
  data: {
    datoCmsDemoWebinar: { seoMetaTags, title, featuredImage, modularBlocks },
  },
}) => {
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} image={featuredImage} />
        <Divider />
        <ModularBlocks items={modularBlocks} />
        <Divider alt="true" />
      </main>
    </Layout>
  );
};

export const DemoWebinarPageTemplateQuery = graphql`
  query DemoWebinarPageTemplateQuery($id: String!) {
    datoCmsDemoWebinar(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      featuredImage {
        ...BannerImageFragment
      }
      modularBlocks {
        ...ContactCallToActionModularBlockFragment
        ...ContentModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...ImageModularBlockFragment
        ...VideoModularBlockFragment
      }
    }
  }
`;

export default DemoWebinarPageTemplate;
